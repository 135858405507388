.page{
  position: relative;
  z-index: 1;
}

div {
  display: block;
}

#slide-0 {
  position: relative;
  background-color: var(--splash-main_bgcolor)
}

#slide-1 {
  background-color: var(--splash-main_bgcolor);
}

#slide-2 {
  background-color: var(--splash-main_bgcolor);
}

#slide-3 {
  background-color: var(--splash-main_bgcolor);
}


/*.hero-new #copy-slide-0{
  max-width: 490px;
*/
/*}*/

.hero-new__slide #slide-0{
  position: fixed;

}

/*.hero-new__video-frame {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--splash-main_bgcolor);
}*/

.hero-new__copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  min-height: 700px;
/*  margin-left: calc(var(--size)*0.08333 - var(--margin)*2*0.08333 - var(--gutter)*11*0.08333 + var(--gutter)*1);
  margin-right: calc(var(--size)*0.08333 - var(--margin)*2*0.08333 - var(--gutter)*11*0.08333 + var(--gutter)*1);*/
  /*max-width: 420px;*/
  width: 100vw;
}
.hero-new #slide-0 {
    top: 0;
    position: -webkit-sticky!important;
    position: sticky!important;
    z-index: 1;
}

.hero-new #slide-0 .hero-new__slide__shadow {
    display: none;
}

.hero-new__slide__shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    z-index: -2;
    box-shadow: 0 -25px 25px rgba(0,0,0,.2);
}

.hero-new #slide-1, .hero-new #slide-2, .hero-new #slide-3{
    top: 0;
    position: -webkit-sticky!important;
    position: sticky!important;
    width: 100vw;
}

.hero-new #copy-slide-1{
  width: 100vw;
  display: block;
  max-width: 100vw;
}

.hero-new__slide {
    display: block;
    position: relative;
    height: 100vh;
    transition: all .1s ease-in-out;
    z-index: 1;
    min-height: 700px;
}

.pro-holder {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  width: 100vw;
  height: 100%;
}

@media only screen and (max-width: 850px) {
    /*.hero-new #slide-1, , .hero-new #slide-2, .hero-new #slide-3 {
      height: 200%;
    }*/

    .hero-new__slide__shadow {
      box-shadow: none;
      display: none;
    }

    #slide-2 {
      display: none;
    }

    .hero-new #slide-0, .hero-new #slide-1 {
      height: 1000px;
      overflow-y: hidden;
    } 

    .hero-new__copy {
      height: 1500px;
      min-height: 300px;
      /*position: fixed;*/
      top: 10%;
      margin-top: 100px;
      display: inherit;
    }


    h1 small, h2 small {
      font-weight: 900;
      font-size: .6em;

    }

    .hero-new__slide {
      min-height: 300px;
    }

    p {
      overflow-y: hidden;
    }
}