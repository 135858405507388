.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  color: #7c7d7e;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #27255b;
}


.preloader[data-v-62763bce] {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 20;
  background: none;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh)*100);
  cursor: wait;
}

.txt-div::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Black with 70% opacity */
  z-index: 0;
  backdrop-filter: blur(1px);
}

.dash-main {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: aliceblue;
  padding-left: 30px;
  padding-right: 30px;
  gap: 30px;
}

.dash-hdr {
  padding-top: 40px;
  color: black;
  font-size: 2.8rem;
}

.dash-lv1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: stretch;
}

h1 {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  border-radius: 10px;
  border-width: 0px;
  height: 50vh;
  width: 40vw;
  border-color: #727d89;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  font-weight: bold;
  font-size: 1em;
  padding:  10px 10px 10px 10px;
  margin: 15px;
  background-color: #123238;
  color: white;
  align-items: stretch;
  flex-wrap: nowrap;
  box-shadow: 0 8px 8px 0 rgb(100 225 225 / 40%), 0 6px 20px 0 rgb(100 225 225 / 40%);
  font-weight: 700;
}

.card-header {
  padding: 0px 4px;
  background-color: var(--splash-main_bgcolor);
  border-color: #000000;
  border-width: 2px;
  border-left-color: #000000;
  border-left-width: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-family: monospace;
  font-size: 2em;
  word-wrap: normal;
  justify-content: center;
  font-weight: bold;

}

.card-img {
  min-height: 100px;
}

h1 small, h2 small {
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8em;
  line-height: 89.8%;
  text-transform: lowercase;
  color: darkslategray;
  letter-spacing: -.09ch;
}

h1 med{
  font-size: 1.2em;
}